export const createHotel = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v2/hotels/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Banner create ${e}`));
};

export const getAllHotelsList = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v2/hotels/get`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting order ${e}`));
};
export const UpdateHotelDetails = (id,body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/hotels/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at update coupon ${e}`));
};
export const getHotelDetailsByHotelId = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v2/hotels/get/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting order ${e}`));
};

export const getAllHotelLogs = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v2/hotelLogs/get/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting order ${e}`));
};

export const getHotelLogsByHotelId = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v2/hotelLogs/get/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting order ${e}`));
};

export const AssignStylistForHotelOrder = (body,id) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/hotel/assign/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at update coupon ${e}`));
};