import ProfileImage from "components/profileImage";
import React, { useEffect, useState } from "react";
import { CardBody } from "reactstrap";

const HotelsList = ({ hotels, handleActiveOrder }) => {
  const [activeCardId, setActiveCardId] = useState();
  const [filterdHotels, setfilterdHotels] = useState([]);
  const [allList, setAllList] = useState([]);
  useEffect(() => {
    setfilterdHotels(hotels);
    setAllList(hotels)
  }, [hotels]);
  const handleActiveCard = id => {
    setActiveCardId(id);
  };
  const handleOrder = ele => {
    handleActiveOrder(ele);
  };
  const handleSearch = search => {
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(
        ele =>
          ele?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setfilterdHotels(filterList);
    } else {
      setfilterdHotels(allList);
    }
  };
  const RenderOrderCards = hotels => {
    if (hotels?.length === 0) {
      return (
        <CardBody role="button" className="">
          hotels are empty
        </CardBody>
      );
    }

    return (
      <>
        <div>
          <div className="search-box m-2">
            <div className="position-relative">
              <i className="bx bx-search-alt search-icon" />
              <input
                className="form-control py-2 rounded-pill bg-white"
                type="search"
                id="example-search-input"
                placeholder=" Search..."
                onChange={event => handleSearch(event.target.value)}
              />
            </div>
          </div>
        </div>
        {hotels?.map((ele, indx) => {
          // console.log(order, "order");
          const FixedClass = "border border-bottom-1 order_card ";
          const activeStatusClass =
            activeCardId === ele?.hotelId
              ? FixedClass + "bg-lightGrey"
              : FixedClass;
          return (
            <CardBody
              role="button"
              key={indx}
              className={activeStatusClass}
              onClick={() => {
                handleOrder(ele);
                handleActiveCard(ele?.hotelId);
              }}
            >
              <div className="d-flex align-items-center buttons">
                <div>
                  <ProfileImage
                    image={ele?.images?.logo}
                    height="50"
                    width="50"
                    classNames="avatar-sm rounded bg-light"
                  />
                </div>
                <div className="ms-3">
                  <h6>{ele?.name}</h6>
                  <div>
                    <span>{ele?.address?.displayAddress}</span>
                  </div>
                </div>
              </div>
            </CardBody>
          );
        })}
      </>
    );
  };
  return (
    <div>
      <CardBody className="p-0">
        <div className="tabs Orders_tabs">
          <div
            className="order-scroll"
            // ref={containerRef}
            // onScroll={handleScrollEvent}
          >
            {RenderOrderCards(filterdHotels)}
          </div>
        </div>
      </CardBody>
    </div>
  );
};

export default HotelsList;
