import Spinner from "components/spinner";
import React, { useState } from "react";
import { Container, Form, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";
import { MetaTags } from "react-meta-tags";
import OrdersList from "pages/Orders/List";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import Breadcrumbs from "components/Common/Breadcrumb";
import MainList from "./mainList";
import Switch from "react-switch";

const AdminOrdersList = () => {
  const [loading, setLoading] = useState(null);
  const [selectedversion, setSelectedVersion] = useState({
    label: "V2.0",
    value: "v2",
  });
  const [IsHotel, setIsHotel] = useState(false);
  console.log(IsHotel, "IsHotel");
  const VersionsList = [
    { label: "V1.0", value: "v1" },
    { label: "V2.0", value: "v2" },
  ];
  const handleChangeVersion = event => {
    setSelectedVersion(event);
  };
  const handleSwitchChange = checked => {
    console.log(checked, "Switch toggled"); // Console log for debugging
    setIsHotel(checked);
  };
  return (
    <div>
      <React.Fragment>
        <Spinner loading={loading} />
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div>
                <Breadcrumbs title="Orders" breadcrumbItem="Orders List" />
                <BreadcrumbSub
                  subtile="Service orders"
                  SubBreadcrumbItem="Orders"
                />
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <div className="me-5 mt-4 d-flex align-items-center ">
                  <Label htmlFor="hotelSwitch" className="mb-0 me-2 font-size-18">
                    Hotel Orders
                  </Label>
                  <Switch
                    id="hotelSwitch"
                    checked={IsHotel}
                    onChange={handleSwitchChange}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                  />
                </div>
                <div style={{ width: "200px" }}>
                  <Label htmlFor="version" className="col-form-label">
                    Select version
                  </Label>
                  <Select
                    name="version"
                    id="version"
                    onChange={event => {
                      handleChangeVersion(event);
                    }}
                    options={VersionsList}
                    value={selectedversion}
                    placeholder="Select..."
                    classNamePrefix={"select2-selection "}
                  />
                </div>
              </div>
            </div>

            <div>
              {selectedversion?.value === "v1" ? <OrdersList /> : <MainList isHotel={IsHotel}/>}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default AdminOrdersList;
