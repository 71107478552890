import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import MomentTimezone from "moment-timezone";
import { Badge, CardBody } from "reactstrap";
import moment from "moment";
import { getStatusByBgClass } from "components/Common/GetStatus";
import { getScreenSize } from "utils/deviceWidth";
import OrderDetailsModal from "./OrderDetailsModal";
import HotelOrderDetailsModal from "./HotelOrderDetailsModal";
const OrdersListPage = ({
  handleActiveOrder,
  handleScroll,
  displayOrders,
  loadMore,
  activeOrder,
  getallOrders,
  isHotel,
  reload
}) => {
  const [ordersList, setOrdersList] = useState([]);
  const [deviceMedia, setDeviceMedia] = useState();
  const [activeCardId, setActiveCardId] = useState();
  const [modal, setModal] = useState(false);

  const containerRef = useRef(null);
  useEffect(() => {
    updateMedia();
  }, [deviceMedia]);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const updateMedia = () => {
    setDeviceMedia(getScreenSize());
  };
  useEffect(() => {
    setOrdersList(displayOrders);
  }, [displayOrders]);

  const handleOrder = order => {
    handleActiveOrder(order);
  };

  const handleScrollEvent = () => {
    const container = containerRef.current;
    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 1 &&
      loadMore
    ) {
      handleScroll();
    }
  };

  const toggleModel = () => {
    setModal(!modal);
  };
  const handleActiveCard = id => {
    setActiveCardId(id);
  };
  const RenderOrderCards = orders => {
    if (orders?.length === 0) {
      return (
        <CardBody role="button" className="">
          Orders are empty
        </CardBody>
      );
    }

    return (
      <>
        {orders?.map((order, indx) => {
          let pendingStatus =
            order?.orderConfirmedByStylist === false &&
            order?.notAvailable?.notAvailable == false;
          let stylistnotavailable = order?.notAvailable == true;
          let createdAt = MomentTimezone.tz(
            order?.createdDate,
            order?.timeZone || "Asia/Calcutta"
          ).format("DD-MMMM-YYYY @ hh:mm A");

          // console.log(order, "order");
          const FixedClass = "border border-bottom-1 order_card ";
          const activeStatusClass =
            activeCardId === order?._id
              ? FixedClass + "bg-lightGrey"
              : FixedClass;
          return (
            <CardBody
              role="button"
              key={indx}
              className={activeStatusClass}
              onClick={() => {
                handleOrder(order);
                if (deviceMedia === "mobile") {
                  toggleModel();
                }
                handleActiveCard(order?._id);
              }}
            >
              <div className="d-flex justify-content-between buttons ">
                <div className="">
                  <h6 className="font-size-18 fw-bold">#{order?.orderId}</h6>
                  <span className="text-secondary ">Order Date: </span>
                  <br />
                  <span className="fw-bold font-size-14">{createdAt}</span>
                  <div className="mt-1">
                    <span className="text-secondary">Customer: </span>
                    {isHotel === true ? (
                      <span className="fw-bold">
                        {order?.userInfo?.name ? order.userInfo?.name : ""}{" "}
                        {/* {order?.user?.lastname ? order.user.lastname : ""} */}
                      </span>
                    ) : (
                      <span className="fw-bold">
                        {order?.user?.firstname ? order.user.firstname : ""}{" "}
                        {order?.user?.lastname ? order.user.lastname : ""}
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-secondary ms-2">
                  <div className="">
                    <Badge
                      className={
                        " badge rounded border border-0 font-size-12 float-end p-1 px-2 " +
                        getStatusByBgClass(
                          " bg",
                          // order?.status === "confirmed" && pendingStatus
                          //   ? "Pending"
                          //   :
                          stylistnotavailable ? "Not available" : order?.status
                        )
                      }
                      pill
                    >
                      {stylistnotavailable
                        ? "Not available"
                        : order?.status === "cancel" ||
                          order?.status === "Cancel"
                        ? "Cancelled"
                        : order?.status === "Compelete"
                        ? "Completed"
                        : order?.status}
                    </Badge>
                  </div>
                  <small className="float-end mt-1">
                    {moment(order?.updatedAt).fromNow()}
                  </small>
                </div>
              </div>
              {order?.isHotel === true && order?.hotelInfo?.hotelId ? (
                 <>
                 <hr className="my-2"/>
                 <div className="d-flex align-items-center mt-1 ">
                      <div className="">
                        <img
                          src={order?.hotelInfo?.hotelId?.images?.logo}
                          width={"30px"}
                          height={"30px"}
                          className=""
                        />
                      </div>
                      <div className="ms-3">
                        <span className="fw-bold">
                          #
                          {order?.hotelInfo?.hotelId
                            ? order?.hotelInfo?.hotelId?.hotelId
                            : ""}
                        </span>
                        <br />
                        <span className="fw-bold">
                          {order?.hotelInfo?.hotelId
                            ? order?.hotelInfo?.hotelId?.name
                            : ""}
                        </span>
                      </div>
                    </div>
                 </>
                  ) : (
                    ""
                  )}
            </CardBody>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <CardBody className="p-0">
        <div className="tabs Orders_tabs">
          <div
            className="order-scroll"
            ref={containerRef}
            onScroll={handleScrollEvent}
          >
            {RenderOrderCards(ordersList)}
          </div>
        </div>
      </CardBody>
      {isHotel === true ? (
        <HotelOrderDetailsModal
          modal={modal}
          toggle={toggleModel}
          activeOrder={activeOrder ? activeOrder : ""}
          getallOrders={getallOrders}
          // handleActiveOrder={handleActiveOrder}
          toggleModel={toggleModel}
          isHotel={isHotel}
          reload={reload}
        />
      ):(
        <OrderDetailsModal
          modal={modal}
          toggle={toggleModel}
          activeOrder={activeOrder ? activeOrder : ""}
          getallOrders={getallOrders}
          handleActiveOrder={handleActiveOrder}
          toggleModel={toggleModel}
        />
      )}
    </div>
  );
};

export default OrdersListPage;
