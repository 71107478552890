import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  getHotelDetailsByHotelId,
  getHotelLogsByHotelId,
} from "services/V2_APIs/hotel.service";
import { MetaTags } from "react-meta-tags";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import noImage from "assets/images/PicUpload.jpg";
import moment from "moment";
import Spinner from "components/spinner";
import { useLocation } from "react-router-dom";
const HotelLogsList = () => {
  const params = useParams();
  const location = useLocation(); // Access the current URL
  const searchParams = new URLSearchParams(location.search); // Parse the query string
  const hotelId = searchParams.get("hotelId"); // Extract the 'hotelId'
  const [list, setList] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  console.log(activeOrder, "actOrderrrr", hotelId);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllHotelLogsListByHotelId(params?.id);
    getHotelDetailsByID(hotelId);
  }, [params?.id]);
  const getAllHotelLogsListByHotelId = id => {
    setLoading(true);
    getHotelLogsByHotelId(id)
      .then(res => {
        if (res?.success === true) {
          setList(res?.data);
        } else {
          setList([]);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getHotelDetailsByID = id => {
    getHotelDetailsByHotelId(id)
      .then(res => {
        let resp = res?.data || [];
        setActiveOrder(resp[0]);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const pageOptions = {
    sizePerPage: 5,
    totalSize: list?.length,
    custom: true,
  };
  const logsList = () => [
    {
      dataField: "hotelId",
      text: "Session ID",
      formatter: (cellContent, row) => {
        return (
          <>
            <div>{row?.hotelId}</div>
          </>
        );
      },
    },
    {
      dataField: "CreatedAt",
      text: "CreatedAt",
      formatter: (cellContent, row) => {
        return (
          <>
            <div>{moment(row?.createdAt).format("DD-MMM-YYYY @ h:mm a")}</div>
          </>
        );
      },
    },

    // {
    //   dataField: "count",
    //   text: "Used",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <>
    //         <Link to={`/Coupon-users/${row?.code}`}>
    //           <div>{row?.count}</div>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];
  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Category | Create Category</title>
          </MetaTags>
          <Spinner loading={loading} />
          <Container fluid>
            <BreadcrumbSub subtile="Logs" SubBreadcrumbItem="Hotel-Logs-List" />
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  className="border p-1 rounded m-2"
                  src={
                    !activeOrder?.images?.logo ||
                    activeOrder?.images?.logo === "undefined"
                      ? noImage
                      : activeOrder?.images?.logo
                  }
                  alt=""
                  width={100}
                  height={100}
                  role="button"
                />
                <div>
                  <h6 className="fw-bold font-size-16 individual_name">
                    # {activeOrder?.hotelId?.toUpperCase()}
                  </h6>
                  <p className="fw-bold mb-1">{activeOrder?.name}</p>
                  <p className="mb-1">
                    {activeOrder?.address?.address1},
                    {activeOrder?.address?.displayAddress}
                  </p>
                </div>
              </div>
              <div className="text-end mb-2">
                <h5 className="fw-bold">
                  Total no.of visitors : {list?.length}
                </h5>
              </div>
            </div>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="list"
                      columns={logsList()}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="list"
                          data={list}
                          columns={logsList()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="list"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    {list?.length == 0 && (
                      <p className="text-center"> No Data Found</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default HotelLogsList;
