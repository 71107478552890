export const CreateEvent = body => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v2/events/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at Create coupon ${e}`));
  };
  