import React, { useEffect, useState } from "react";
import * as _ from "lodash";

import { Col, Row, Label, Button, Input, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";

import Select from "react-select";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import { updatefilefeildService } from "services/services.service";
import Checkbox from "react-custom-checkbox";
import checkMark from "assets/icons-image/check-mark.png";

const optionalRow = {
  type: "",
  name: "",
  value: "",
  image: "",
  displayType: "",
  order: "",
  status: "",
  serviceExtra: 0,
  productExtra: 0,
  optionCategory: "service",
};

const optionInitVal = {
  optionTitle: "",
  optionType: "",
  values: [optionalRow],
  optionCategory: "service",
};

const OptionTypes = [
  { label: "Multi Select", value: "Multi Select" },
  { label: "Single Select", value: "Single Select" },
];
const OptionCategoryTypes = [
  { label: "service", value: "service" },
  { label: "add-on", value: "add-on" },
];
const OptionDisplayTypes = [
  { label: "Text with Image", value: "Text with Image" },
  { label: "Only Text", value: "Only Text" },
];
const optionStatusList = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const customStyles = {
  control: base => ({
    ...base,
    height: 28,
    minHeight: 28,
  }),
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
const formatListByOrder = list => {
  let tempList = _.cloneDeep(list);
  tempList = tempList.map(ele => {
    ele.values = _.orderBy(ele.values, ["order"], ["asc"]);
    return ele;
  });
  return tempList;
};

const OptionInfoModal = ({ toggle, initValues, reloadService }) => {
  let initVal = initValues?.optionsDetails
    ? _.cloneDeep(initValues?.optionsDetails)
    : [optionInitVal];
  const [values, setValues] = useState(initVal);
  const [images, setimages] = useState({});

  const addOption = () => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues.push(optionInitVal);
    setValues(tempValues);
  };

  const handleRootChange = (field, index, value) => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[index][field] = value;
    setValues([...tempValues]);
  };

  const handleChange = (fieldName, subIndex, parentIndex, value) => {
    let tempValues = JSON.parse(JSON.stringify(values));

    if (fieldName === "serviceExtra") {
      tempValues[parentIndex]["values"][subIndex][fieldName] = value
        ? parseFloat(value)
        : 0;
    } else {
      tempValues[parentIndex]["values"][subIndex][fieldName] = value;
    }

    setValues([...tempValues]);
  };

  function handleAcceptedFiles(subIndex, parentIndex, files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    //For Showing selected Local Image
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[parentIndex]["values"][subIndex]["image"] = files[0];
    setValues([...tempValues]);

    const imagesdata = { ...images };
    imagesdata[`option${parentIndex}${subIndex}`] = files[0];
    setimages(imagesdata);
  }

  const handleDeleteOption = index => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues.splice(index, 1);
    setValues([...tempValues]);
  };

  const handleDeleteRow = (subInd, parentInd) => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[parentInd]["values"].splice(subInd, 1);
    setValues([...tempValues]);
  };
  const handleAddRow = parentInd => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[parentInd]["values"].push(optionalRow);
    setValues([...tempValues]);
  };

  const onSubmit = () => {
    let tempValue = formatListByOrder(values);

    tempValue.forEach(option => {
      option.values.forEach(value => {
        if (
          value.serviceExtra === undefined ||
          value.serviceExtra === null ||
          value.serviceExtra === ""
        ) {
          value.serviceExtra = 0;
        }
        if (
          value.productExtra === undefined ||
          value.productExtra === null ||
          value.productExtra === ""
        ) {
          value.productExtra = 0;
        }
      });
    });

    let formData = new FormData();
    formData.set("optionsDetails", JSON.stringify(tempValue));
    for (let key in images) {
      formData.set(key, images[key]);
    }

    updatefilefeildService(initValues?._id, formData)
      .then(res => {
        reloadService();
        toggle();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const renderCheckbox = (index, val) => {
    return (
      <Checkbox
        onChange={(value, event) => {
          handleRootChange("mandatory", index, value);
        }}
        checked={val === "true" || val === true ? true : false}
        icon={<img src={checkMark} style={{ width: 12 }} alt="" />}
        borderColor="#ccc"
        borderRadius={0}
        borderWidth={1}
        label="Is Mandatory"
        className="p-2"
        type="pointer"
      />
    );
  };

  return (
    <>
      <div id="servicce_options_container">
        <Row>
          <div className="d-flex mb-2 justify-content-end">
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => addOption()}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Add Options</span>
            </Link>
          </div>
        </Row>
        {values &&
          values?.map((ele, ind) => {
            return (
              <div key={ind} className="p-2">
                <Row>
                  {/** Option Title */}
                  <Col md={3}>
                    <Label htmlFor="name" className=" col-form-label">
                      Option Title
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      className="form-control"
                      id="name"
                      placeholder="Enter Option Title"
                      name="name"
                      onChange={event =>
                        handleRootChange("optionTitle", ind, event.target.value)
                      }
                      value={ele.optionTitle}
                    />
                  </Col>

                  {/** OptionType */}
                  <Col md={3}>
                    <Label htmlFor="type" className=" col-form-label">
                      Option Type
                    </Label>
                    <Select
                      id="type"
                      value={{
                        label: ele.optionType,
                        value: ele.optionType,
                      }}
                      onChange={event =>
                        handleRootChange("optionType", ind, event.value)
                      }
                      options={OptionTypes}
                      className="custom_dropdown_sm"
                      classNamePrefix={"select2-selection "}
                      styles={customStyles}
                    />
                  </Col>
                  <Col md={3}>
                    <Label htmlFor="optionCategory" className=" col-form-label">
                      Option Category
                    </Label>
                    <Select
                      id="optionCategory"
                      value={{
                        label: ele.optionCategory,
                        value: ele.optionCategory,
                      }}
                      onChange={event =>
                        handleRootChange("optionCategory", ind, event.value)
                      }
                      options={OptionCategoryTypes}
                      className="custom_dropdown_sm"
                      classNamePrefix={"select2-selection "}
                      styles={customStyles}
                    />
                  </Col>
                  <Col className="d-flex align-items-end">
                    <div className="option_delete me-4">
                      <i
                        className="mdi mdi-delete font-size-18 text-danger text-end"
                        onClick={() => {
                          handleDeleteOption(ind);
                        }}
                      ></i>
                      {ele.values && ele.values.length === 0 && (
                        <Button
                          className="btn btn-sm ms-4"
                          color="primary"
                          outline
                          onClick={() => {
                            handleAddRow(ind);
                          }}
                        >
                          <i className="mdi mdi-plus-thick pe-2"></i>Sub Options
                        </Button>
                      )}
                    </div>
                    {renderCheckbox(ind, ele?.mandatory)}
                  </Col>
                </Row>
                {ele.values &&
                  ele.values?.map((subEle, subInd) => {
                    const { image } = subEle;
                    return (
                      <Row
                        className="option_row py-2 my-3 m-0"
                        key={ind + " " + subInd}
                      >
                        <Col md={11}>
                          <Row>
                            {/** OptionType */}
                            {/* <Col md={3}>
                              <Label htmlFor="type" className=" col-form-label">
                                Option Type
                              </Label>
                              <Select
                                id="type"
                                value={{
                                  label: subEle.type,
                                  value: subEle.type,
                                }}
                                onChange={event => {
                                  handleChange(
                                    "type",
                                    subInd,
                                    ind,
                                    event.value
                                  );
                                }}
                                options={OptionTypes}
                                className="custom_dropdown_sm"
                                classNamePrefix={"select2-selection "}
                                styles={customStyles}
                              />
                            </Col> */}

                            {/** Option Name */}
                            <Col md={3}>
                              <Label
                                htmlFor="optionName"
                                className=" col-form-label"
                              >
                                Option Name
                              </Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                className="form-control"
                                id="optionName"
                                placeholder="Enter Option Name"
                                name="optionName"
                                onChange={event =>
                                  handleChange(
                                    "name",
                                    subInd,
                                    ind,
                                    event.target.value
                                  )
                                }
                                value={subEle.name}
                              />
                            </Col>

                            {/** Option Value */}
                            <Col md={3}>
                              <Label
                                htmlFor="optionvalue"
                                className=" col-form-label"
                              >
                                Option Value
                              </Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                className="form-control"
                                id="optionvalue"
                                placeholder="Enter Option Value"
                                name="optionvalue"
                                onChange={event =>
                                  handleChange(
                                    "value",
                                    subInd,
                                    ind,
                                    event.target.value
                                  )
                                }
                                value={subEle.value}
                              />
                            </Col>

                            {/** Option Display Type */}
                            <Col md={3}>
                              <Label
                                htmlFor="displayType"
                                className=" col-form-label"
                              >
                                Option Display Type
                              </Label>
                              <Select
                                id="displayType"
                                value={{
                                  label: subEle.displayType,
                                  value: subEle.displayType,
                                }}
                                onChange={event => {
                                  handleChange(
                                    "displayType",
                                    subInd,
                                    ind,
                                    event.value
                                  );
                                }}
                                options={OptionDisplayTypes}
                                classNamePrefix={"select2-selection "}
                                className="custom_dropdown_sm"
                                styles={customStyles}
                              />
                            </Col>
                            {/** Order */}
                            <Col md={3}>
                              <Label
                                htmlFor="order"
                                className=" col-form-label"
                              >
                                Order
                              </Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                className="form-control"
                                id="order"
                                placeholder="Order"
                                name="order"
                                onChange={event =>
                                  handleChange(
                                    "order",
                                    subInd,
                                    ind,
                                    event.target.value
                                  )
                                }
                                value={subEle.order}
                              />
                            </Col>

                            {/** Status */}
                            <Col md={3}>
                              <Label
                                htmlFor="optionStatus"
                                className=" col-form-label"
                              >
                                Status
                              </Label>
                              <Select
                                id="optionStatus"
                                value={{
                                  label: subEle.status,
                                  value: subEle.status,
                                }}
                                onChange={event => {
                                  handleChange(
                                    "status",
                                    subInd,
                                    ind,
                                    event.value
                                  );
                                }}
                                options={optionStatusList}
                                classNamePrefix={"select2-selection "}
                                className="custom_dropdown_sm"
                                styles={customStyles}
                              />
                            </Col>

                            <Col md={3}>
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(
                                    subInd,
                                    ind,
                                    acceptedFiles
                                  );
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone ser_img_drop">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>Image</h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </Col>

                            {image && (
                              <>
                                <Col>
                                  <div className="ser_img d-flex">
                                    <ProfileImage image={image} />
                                    <div className="ms-2">
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {image.name || image.filename}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{image.formattedSize}</strong>
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                            <Row>
                              <Col md={4}>
                                <Label
                                  htmlFor="serviceExtra"
                                  className="col-form-label"
                                >
                                  Service Extra
                                </Label>
                                <Input
                                  type="text"
                                  bsSize="sm"
                                  className="form-control"
                                  id="serviceExtra"
                                  placeholder="Enter Percentage"
                                  name="serviceExtra"
                                  onChange={event =>
                                    handleChange(
                                      "serviceExtra",
                                      subInd,
                                      ind,
                                      event.target.value
                                    )
                                  }
                                  value={
                                    subEle?.serviceExtra
                                      ? subEle?.serviceExtra
                                      : ""
                                  }
                                />
                              </Col>
                              {/* <Col md={4}>
                                <Label
                                  htmlFor="productExtra"
                                  className="col-form-label"
                                >
                                  Product Extra
                                </Label>
                                <Input
                                  type="text"
                                  bsSize="sm"
                                  className="form-control"
                                  id="productExtra"
                                  placeholder="Enter Percentage"
                                  name="productExtra"
                                  onChange={event =>
                                    handleChange(
                                      "productExtra",
                                      subInd,
                                      ind,
                                      event.target.value
                                    )
                                  }
                                  value={subEle?.productExtra}
                                />
                              </Col> */}
                            </Row>
                          </Row>
                        </Col>
                        <Col md={1}>
                          <div className="ser_row_action">
                            <i
                              className="mdi mdi-delete font-size-18 text-danger"
                              onClick={() => {
                                handleDeleteRow(subInd, ind);
                              }}
                            ></i>
                            {ele.values.length === subInd + 1 && (
                              <i
                                className="mdi mdi-plus-thick font-size-18 text-success"
                                onClick={() => {
                                  handleAddRow(ind);
                                }}
                              ></i>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            );
          })}

        <div className="d-flex mt-4 justify-content-end">
          <Button
            color="light"
            className="w-md btn-sm me-2"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-md btn-sm ms-2"
            onClick={() => onSubmit()}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default OptionInfoModal;
