import axios from "axios";

export const getProviders = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/providers`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider ${e}`));
};

export const createProvider = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider ${e}`));
};

export const getProvideById = Id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/get/${Id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider ${e}`));
};

export const getPhotos = Id => {
  fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/photos/${Id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider ${e}`));
};

export const updateProvider = (Id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/update/${Id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider ${e}`));
};

export const deleteprovider = Id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/delete/${Id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider ${e}`));
};
export const updateProviderAccess = (Id, access) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/providerAccess/${Id}/${access}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider ${e}`));
};

export const getProvidersbyLocation = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/provider/byLocation`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //   Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at provider by location ${e}`));
};

export const getProvidersByZipcodeAndService = body => {
    return fetch(
      `${process.env.REACT_APP_DATABASEURL}/api/v2/provider/service-details`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //   Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at provider by location ${e}`));
  };
  