export const getOrdersCountsAndList = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/admin/counts`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};


export const getOrdersCountsAndListHotel = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/admin/counts?hotel=true`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const getHotelOrdersByStatus = status => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/admin/status?status=${status}&hotel=true`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};

export const getOrdersByStatus = status => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/admin/status?status=${status}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};


export const getOrderDetailsByOrderId = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/admin/details/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting order ${e}`));
};

export const ConfirmOrder = (OrderId, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/confirm/${OrderId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Admin Notes Update ${e}`));
};

export const RescheduleOrder = (body, id) => {
  console.log("Request Payload:", body);
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/reschedule/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      console.log(res, "resssssssssss");
      return res.json();
    })
    .catch(e => console.log(`error at Service Update file field ${e}`));
};

export const getAllPaidOrders = (
  paymentType,
  month,
  year,
  fromDate,
  ToDate
) => {
  let url = `${process.env.REACT_APP_DATABASEURL}/api/v2/orders/admin/paid?`;
  if (paymentType === "View All" ? paymentType = "": paymentType) {
    url += `type=${paymentType}`;
  }
  if (!fromDate || !ToDate) {
    if (month) {
      url += `&month=${month}`;
    }
    if (year) {
      url += `&year=${year}`;
    }
  }
  if (fromDate) {
    url += `&start=${fromDate}`;
  }
  if (ToDate) {
    url += `&end=${ToDate}`;
  }

  // debugger;
  // Fetch the orders
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      // console.log("allordersComresponeres==>", res, pagenumber);
      console.log(res, "response statements");
      return res.json();
    })
    .catch(e => console.log(`error at orders ${e}`));
};
