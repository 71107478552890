import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Button, Card, Col, Container, Row } from "reactstrap";
import CreateHotel from "./CreateHotel";
import HotelsList from "./HotelsList";
import {
  getAllHotelsList,
  getHotelDetailsByHotelId,
} from "services/V2_APIs/hotel.service";
import HotelDetailsPage from "./HotelDetailsPage";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import Breadcrumb from "components/Common/Breadcrumb";
import Spinner from "components/spinner";

const HotelIndexPage = () => {
  const [modal, setModal] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const [ActiveData, setActiveData] = useState();
  const [loading, setLoading] = useState(false);
  const ToggleCreateHotel = () => {
    setModal(!modal);
  };
  useEffect(() => {
    getAllHotels();
  }, []);
  const getAllHotels = () => {
    setLoading(true);
    getAllHotelsList()
      .then(res => {
        setHotels(res?.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getHotelDetailsByID = id => {
    setLoading(true);
    getHotelDetailsByHotelId(id)
      .then(res => {
        let resp = res?.data || [];
        setActiveOrder(resp);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  const handleActiveOrder = ele => {
    getHotelDetailsByID(ele?.hotelId);
  };
  const handleActiveEdit = ele => {
    setActiveData(ele);
    ToggleCreateHotel();
  };
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Spinner loading={loading} />
          <Container fluid>
            <div>
              <Breadcrumb title="Hotels" breadcrumbItem="Hotels List" />
              <BreadcrumbSub
                subtile="Hotels Details"
                SubBreadcrumbItem="Hotels"
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                className="btn btn-md"
                onClick={() => {
                  ToggleCreateHotel();
                  setActiveData({});
                }}
              >
                Create hotel
              </Button>
            </div>
          </Container>
          <CreateHotel
            toggle={ToggleCreateHotel}
            modal={modal}
            reload={getAllHotels}
            ActiveData={ActiveData}
            handleActiveOrder={handleActiveOrder}
          />
          <Row className="px-4 mt-3">
            <Col md="4">
              <Card>
                <HotelsList
                  hotels={hotels}
                  handleActiveOrder={handleActiveOrder}
                />
              </Card>
            </Col>
            <Col md="8" className="Order_details_page">
              <HotelDetailsPage
                activeOrder={activeOrder}
                handleActiveEdit={handleActiveEdit}
              />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </>
  );
};

export default HotelIndexPage;
