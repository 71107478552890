import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Card,
  Row,
  Col,
  CardBody,
  UncontrolledTooltip,
  Label,
  Input,
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Breadcrumbs from "components/Common/Breadcrumb";
import ProfileImage from "./../../components/profileImage";
import { getAllCoupons } from "services/coupons.service";
import moment from "moment";
import CreateCoupon from "./Create";
import Spinner from "components/spinner";
import Couponview from "./view";
import { Link } from "react-router-dom";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const CouponLists = props => {
  const { SearchBar } = Search;
  const [couponsList, setCouponsList] = useState([]);
  const [modal, setModal] = useState(false);
  const [activeData, setActiveData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeObjData, setActiveObjData] = useState();
  const [modalview, setmodalView] = useState(false);
  const typeFilterOptions = ["Active", "Inactive", "Expired"];
  const [typeFilter, setTypeFilter] = useState([]);
  const [tempList, setTempList] = useState([]);
console.log(couponsList,"couponsList")
  const toggle = () => {
    if (modal) {
      setActiveData(null);
    }
    setModal(!modal);
  };
  const toggleView = () => {
    setmodalView(!modalview);
  };
  useEffect(() => {
    getCouponsList();
  }, []);

  const getCouponsList = () => {
    setLoading(true);
    const currentDate = moment(); // Get the current date and time

    getAllCoupons()
      .then(res => {
        const updatedCouponsList = res?.data.map(coupon => {
          const endDate = moment(coupon.endDate);

          // Calculate the end of the day (12:00 AM)
          const endOfDay = moment(endDate).endOf("day");

          if (currentDate.isAfter(endOfDay)) {
            return {
              ...coupon,
              status: "Expired",
            };
          }

          return coupon;
        });

        updatedCouponsList.sort((a, b) => {
          const statusOrder = { Active: 0, Inactive: 1, Expired: 2 };
          return statusOrder[a.status] - statusOrder[b.status];
        });

        setCouponsList(updatedCouponsList);
        setTempList(updatedCouponsList);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleCheckboxChange = text => {
    setLoading(true);
    let indx = typeFilter.indexOf(text);
    if (indx >= 0) {
      typeFilter?.splice(indx, 1);
    } else {
      typeFilter?.push(text);
    }
    if (typeFilter.length > 0) {
      let newList = _.filter(tempList, function (obj) {
        if (typeFilter?.includes(obj?.status)) {
          return obj;
        }
      });
      setCouponsList(newList);
    } else {
      setCouponsList(tempList);
    }
    setTypeFilter(typeFilter);
    setLoading(false);
  };
  const checkboxItems = () => {
    return (
      <React.Fragment>
        {typeFilterOptions?.map((option, index) => {
          return (
            <div className="d-flex me-3 align-items-center" key={index}>
              <Input
                key={option}
                id={option}
                type="checkbox"
                className="form-check-Inpu me-2 cursor-pointer"
                onChange={() => handleCheckboxChange(option)}
              />
              <Label
                className="form-check-Label fw-bold mb-0  pt-1  cursor-pointer"
                htmlFor={option}
              >
                {option}
              </Label>
            </div>
          );
        })}
      </React.Fragment>
    );
  };
  // Table Data
  const customer = () => [
    {
      dataField: "image",
      text: "Image",
      formatter: (cellContent, row) => (
        <ProfileImage
          image={row.bannerImage}
          classNames="rounded-circle avatar-sm"
        />
      ),
    },

    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "type",
      text: "Coupon Type",
      formatter: (cellContent, row) => {
        return (
          <>
            <div
              className={
                row?.type === "Discount On Product"
                  ? "text-success"
                  : "" || row?.type === "Discount On Service"
                  ? "text-info"
                  : ""
              }
            >
              {row?.type}
            </div>
          </>
        );
      },
    },
    {
      dataField: "discountType",
      text: "DiscountType",
    },
    {
      dataField: "usage",
      text: "Usage",
    },
    {
      dataField: "discountAmount",
      text: "DiscountAmnt",
    },
    {
      dataField: "StartDate",
      text: "Start Date",
      formatter: (cell, row) => (
        <>{moment(row?.startDate).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      dataField: "endDate",
      text: "End Date",
      formatter: (cell, row) => (
        <>{moment(row?.endDate).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => {
        if (row?.status === "Expired") {
          return (
            <div className="d-flex align-items-center text-danger">
              <i className="bx bx-block me-1 text-danger fw-bold"></i>
              Expired
            </div>
          );
        } else if (row.status === "Active") {
          return (
            <>
              <div className="d-flex align-items-center">
                <i className="bx bxs-circle me-1 text-success"></i>
                Active
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="d-flex align-items-center">
                <i className="bx bxs-circle me-1 text-danger"></i>
                Inactive
              </div>
            </>
          );
        }
      },
    },
    {
      dataField: "count",
      text: "Used",
      formatter: (cellContent, row) => {
        return (
          <>
            <Link to={`/Coupon-users/${row?.code}`}>
              <div>{row?.count}</div>
            </Link>
          </>
        );
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div
                className="text-success"
                onClick={() => {
                  setModal(true);
                  setActiveData(row);
                }}
                role="button"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
              <div
                to="#"
                className="text-info"
                onClick={() => {
                  toggleView();
                  setActiveObjData(row);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: couponsList?.length, // replace later with size(customers),
    custom: true,
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Coupons</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Coupons" breadcrumbItem="Coupons List" />
            <BreadcrumbSub subtile="Coupons List" SubBreadcrumbItem="Coupons" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="couponsList"
                      columns={customer()}
                      data={couponsList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="couponsList"
                          data={couponsList}
                          columns={customer()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center">
                                  <div className="search-box d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center ms-3 mb-3">
                                    {checkboxItems()}
                                  </div>
                                </div>
                                <div>
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={() => toggle()}
                                    >
                                      <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                      Create Coupon
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="couponsList"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    {couponsList?.length == 0 && (
                      <p className="text-center"> No Data Found</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      <CreateCoupon
        toggle={toggle}
        modal={modal}
        coupondata={activeData}
        reload={getCouponsList}
      />
      <Couponview
        toggle={toggleView}
        modal={modalview}
        coupondata={activeObjData}
      />
      <Spinner loading={loading} />
    </>
  );
};

export default CouponLists;
