import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import noImage from "assets/images/PicUpload.jpg";
import moment from "moment";
import csc from "countries-states-cities";

const Couponview = ({ modal, toggle, coupondata }) => {
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    if (coupondata) {
      let list = [];
      csc.getAllCountries().forEach(ele => {
        let obj = {
          value: ele.iso2,
          id: ele.id,
          label: ele.name,
        };
        list.push(obj);
      });
      setCountriesList(list);
    }
  }, [coupondata]);
  const fullcountryname = countriesList?.filter(
    ele => ele?.value === coupondata?.country
  );

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
      <ModalHeader toggle={toggle}>Coupon View</ModalHeader>
      <ModalBody className="bg-light">
        <Container fluid>
          <Row className="m-0">
            <Col md={4} className="services_images_info ps-0 pe-2">
              <Card className="mb-2">
                <CardBody>
                  <Row className="mb-2">
                    {" "}
                    <Col md={6}>
                      <p className="fw-bold mb-0">Coupon Image</p>{" "}
                    </Col>
                    <Col md={6}> </Col>
                  </Row>
                  <div className="banner">
                    <img
                      alt="banner"
                      src={
                        coupondata?.bannerImage
                          ? coupondata?.bannerImage
                          : noImage
                      }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={8} className="p-0">
              <Card>
                <CardBody>
                  <Row className="m-0">
                    <Col md={12} className="mb-1 px-0 view_detail">
                      <Row className="m-0 mb-2">
                        <Col md={4} className="mt-1">
                          <p className="fw-bold mb-1">Title</p>
                          <p className="text-muted">
                            {coupondata?.title ? coupondata?.title : "_"}
                          </p>
                        </Col>
                        <Col md={3} className="mt-1">
                          <p className="fw-bold mb-1">Code</p>
                          <p className="text-muted">
                            {coupondata?.code ? coupondata?.code : "_"}
                          </p>
                        </Col>
                        <Col md={4} className="mt-1">
                          <p className="fw-bold mb-1">Usage</p>
                          <p className="text-muted">
                            {coupondata?.usage ? coupondata?.usage : "0"}
                          </p>
                        </Col>
                        <Col md={1} className="mt-1">
                          <p className="fw-bold mb-1">Used</p>
                          <p className="text-muted">
                            {coupondata?.count ? coupondata?.count : "0"}
                          </p>
                        </Col>
                      </Row>
                      <Row className="m-0 mb-2">
                        <Col md={3} className="mt-1">
                          <p className="fw-bold mb-1">Discount Type</p>
                          <p className="text-muted">
                            {coupondata?.discountType
                              ? coupondata?.discountType
                              : "_"}
                          </p>
                        </Col>
                        <Col md={3} className="mt-1">
                          <p className="fw-bold mb-1">Discount Amount</p>
                          <p className="text-muted">
                            {coupondata?.discountAmount
                              ? coupondata?.discountAmount
                              : "_"}
                          </p>
                        </Col>
                        <Col md={3} className="mt-1">
                          <p className="fw-bold mb-1">minimumPurchase</p>
                          <p className="text-muted">
                            {coupondata?.minimumPurchase
                              ? coupondata?.minimumPurchase
                              : "_"}
                          </p>
                        </Col>
                        <Col md={3} className="mt-1">
                          <p className="fw-bold mb-1">Coupon Type</p>
                          <p className="text-muted">
                            {coupondata?.type ? coupondata?.type : "_"}
                          </p>
                        </Col>
                      </Row>
                      <Row className="m-0 mb-2">
                        <Col md={4} className="mt-1">
                          <p className="fw-bold mb-1">start Date</p>
                          <p className="text-muted">
                            {moment(coupondata?.startDate).format(
                              "DD-MMMM-yyyy"
                            )}
                          </p>
                        </Col>
                        <Col md={4} className="mt-1">
                          <p className="fw-bold mb-1">End Date</p>
                          <p className="text-muted">
                            {moment(coupondata?.endDate).format("DD-MMMM-yyyy")}
                          </p>
                        </Col>
                        <Col md={4} className="mt-1">
                          <p className="fw-bold mb-1">Country</p>
                          <p className="text-muted">{fullcountryname[0]?.label}</p>
                        </Col>
                        <Col md={4} className="mt-1">
                          <p className="fw-bold mb-1">Updated At</p>
                          <p className="text-muted">
                            {moment(coupondata?.updatedAt).format(
                              "DD-MMMM-yyyy"
                            )}
                          </p>
                        </Col>
                        <Col md={3} className="mt-1">
                          <p className="fw-bold mb-1">Status</p>
                          <p
                            className={
                              coupondata?.status === "Active"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {coupondata?.status ? coupondata.status : "_"}
                          </p>
                        </Col>
                      </Row>
                      <Row className="m-0 mb-2">
                        <Col md={12} className="mt-1">
                          <p className="fw-bold mb-1">Description</p>
                          {coupondata?.description ? (
                            <div
                              className="text-muted"
                              dangerouslySetInnerHTML={{
                                __html: coupondata?.description,
                              }}
                            />
                          ) : (
                            "_"
                          )}{" "}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default Couponview;
