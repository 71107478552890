import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import AdminNotes from "pages/ArtistQuoteForms/AdminNotes";
import AnimatedViewsCounter from "../../components/Common/AnimatedViewsCounter";
import { getHotelLogsByHotelId } from "services/V2_APIs/hotel.service";
import noImage from "assets/images/PicUpload.jpg";

const HotelDetailsPage = ({ activeOrder, handleActiveEdit }) => {
  const [hotelDetails, sethotelDetails] = useState({});
  const [views, setViews] = useState();
  useEffect(() => {
    if (activeOrder && activeOrder?.length) {
      sethotelDetails(activeOrder[0]);
      getHotelViewsByHotelId(activeOrder[0]?._id);
    }
  }, [activeOrder]);
  const getHotelViewsByHotelId = id => {
    // setLoading(true);
    getHotelLogsByHotelId(id)
      .then(res => {
        if (res?.data) {
          setViews(res?.data?.length || 0);
        }
      })
      .catch(err => {
        // setLoading(false);
      });
  };
  return (
    <>
      <div className="Order_details_card">
        {activeOrder && activeOrder?.length ? (
          <>
            <Card className="mb-2">
              <CardBody>
                <div className="d-flex justify-content-end align-items-center mb-3">
                  <Badge
                    className="font-size-12 badge-soft-success py-1 px-3 me-2"
                    color={"bg-success"}
                    role="button"
                    pill
                    onClick={() => {
                      handleActiveEdit(hotelDetails);
                    }}
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                </div>
                <Row className="">
                  <Col md="12">
                    <Row>
                      <Col md="3" className="text-center">
                        <img
                          src={hotelDetails?.images?.logo}
                          width={"150px"}
                          height={"150px"}
                          className="rounded me-2 border p-1"
                        />
                        <AnimatedViewsCounter endValue={views} />
                      </Col>
                      <Col md="9">
                        <Row>
                          <Col md={3} className="">
                            <FormGroup className="">
                              <Label htmlFor="validationCustom01">
                                Session ID
                              </Label>
                              <Input
                                name="hotelId"
                                placeholder="hotelId"
                                type="text"
                                classtitle="form-control"
                                id="hotelId"
                                disabled
                                value={hotelDetails?.hotelId?.toUpperCase()}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={5} className="">
                            <FormGroup className="">
                              <Label htmlFor="validationCustom01">
                                Hotel Name
                              </Label>
                              <Input
                                name="name"
                                placeholder="name"
                                type="text"
                                classtitle="form-control"
                                id="name"
                                disabled
                                value={hotelDetails?.name}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} className="">
                            <FormGroup className="">
                              <Label htmlFor="validationCustom01">
                                Markup Percentage
                              </Label>
                              <Input
                                name="percetage"
                                placeholder="percetage"
                                type="text"
                                classtitle="form-control"
                                id="percetage"
                                disabled
                                value={hotelDetails?.prices?.markupPercentage}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={5} className="">
                            <FormGroup className="">
                              <Label htmlFor="description">
                                Secondary Logo
                              </Label>
                              <div>
                                <img
                                  src={
                                    hotelDetails?.images?.secondaryLogo
                                      ? hotelDetails?.images?.secondaryLogo
                                      : noImage
                                  }
                                  width={"200px"}
                                  height={"150px"}
                                  className="rounded me-2 border p-1"
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={6} className="">
                            <FormGroup className="">
                              <Label htmlFor="description">Description</Label>
                              <textarea
                                name="description"
                                className="form-control"
                                id="description"
                                rows={3}
                                disabled
                                value={hotelDetails?.description}
                              ></textarea>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <h5 className="fw-bold ms-2 mb-2">Address Details</h5>
            <Card>
              <CardBody>
                <Row className="">
                  <Col md={3} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">Country</Label>
                      <Input
                        name="Country"
                        placeholder="Country"
                        type="text"
                        classtitle="form-control"
                        id="Country"
                        disabled
                        value={hotelDetails?.address?.country}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">State</Label>
                      <Input
                        name="state"
                        placeholder="state"
                        type="text"
                        classtitle="form-control"
                        id="state"
                        disabled
                        value={hotelDetails?.address?.state}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">City</Label>
                      <Input
                        name="city"
                        placeholder="city"
                        type="text"
                        classtitle="form-control"
                        id="city"
                        disabled
                        value={hotelDetails?.address?.city}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">Zipcode</Label>
                      <Input
                        name="zipcode"
                        placeholder="zipcode"
                        type="text"
                        classtitle="form-control"
                        id="zipcode"
                        disabled
                        value={hotelDetails?.address?.zipcode}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} className="">
                    <FormGroup className="">
                      <Label htmlFor="address">Address</Label>
                      <textarea
                        name="address"
                        className="form-control"
                        id="address"
                        rows={3}
                        disabled
                        value={hotelDetails?.address?.displayAddress}
                      ></textarea>
                    </FormGroup>
                  </Col>
                  <Col md={6} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">LandMark</Label>
                      <Input
                        name="landmark"
                        placeholder="landmark"
                        type="text"
                        classtitle="form-control"
                        id="landmark"
                        disabled
                        value={hotelDetails?.address?.landmark}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">Location Type</Label>
                      <Input
                        name="locationType"
                        placeholder="locationType"
                        type="text"
                        classtitle="form-control"
                        id="locationType"
                        disabled
                        value={hotelDetails?.address?.location?.locationType}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">Latitude</Label>
                      <Input
                        name="latitude"
                        placeholder="latitude"
                        type="text"
                        classtitle="form-control"
                        id="latitude"
                        disabled
                        value={hotelDetails?.address?.location?.latitude}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">Longitude</Label>
                      <Input
                        name="longitude"
                        placeholder="longitude"
                        type="text"
                        classtitle="form-control"
                        id="longitude"
                        disabled
                        value={hotelDetails?.address?.location?.longitude}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <h5 className="fw-bold ms-2 mb-2">Contact Details</h5>
            <Card>
              <CardBody>
                {hotelDetails?.contactDetails?.contacts &&
                  hotelDetails?.contactDetails?.contacts?.map(
                    (contact, index) => {
                      return (
                        <div key={index} className="border p-2 m-1 rounded">
                          <Row key={index} className="">
                            <Col sm={4}>
                              <Label className="col-form-label">Name</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id={`contacts.${index}.name`}
                                placeholder="Name"
                                name={`contactDetails.contacts[${index}].name`}
                                disabled
                                value={contact?.name}
                              />
                            </Col>
                            <Col sm={4}>
                              <Label
                                htmlFor={`contacts.${index}.position`}
                                className="col-form-label"
                              >
                                Position
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id={`contacts.${index}.position`}
                                placeholder="Position"
                                name={`contactDetails.contacts[${index}].position`}
                                disabled
                                value={contact?.position}
                              />
                            </Col>
                            <Col sm={4}>
                              <Label
                                htmlFor={`contacts.${index}.number`}
                                className="col-form-label"
                              >
                                Number
                              </Label>

                              <PhoneInput
                                className="custom_country_phoneNumber phone_number-disabled"
                                id={`contacts.${index}.number`}
                                placeholder="mobile number"
                                name={`contactDetails.contacts[${index}].number`}
                                international={false}
                                defaultCountry={"IN"}
                                value={contact?.number}
                              />
                            </Col>
                            <Col sm={6}>
                              <Label className="col-form-label">notes</Label>
                              <textarea
                                name={`contactDetails.contacts[${index}].notes`}
                                className="form-control"
                                rows={3}
                                placeholder="Notes"
                                value={contact?.notes}
                                disabled
                              ></textarea>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  )}
              </CardBody>
            </Card>
            <h5 className="fw-bold ms-2 mb-2">Theme Details</h5>
            <Card>
              <CardBody>
                <Row className="">
                  <Col md={4} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">Theme color 1</Label>
                      <Input
                        name="theme1"
                        placeholder="theme1"
                        type="text"
                        classtitle="form-control"
                        id="theme1"
                        disabled
                        value={hotelDetails?.styles?.theme1}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className="">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom01">Theme color 2</Label>
                      <Input
                        name="theme2"
                        placeholder="theme2"
                        type="text"
                        classtitle="form-control"
                        id="theme2"
                        disabled
                        value={hotelDetails?.styles?.theme2}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <AdminNotes activeOrder={activeOrder} />
          </>
        ) : (
          <Card className="p-3 pt-0 empty_order">
            <CardBody className="border-3">
              <div className="text-center">
                <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
              </div>
              <p className="text-center">Please Select Order</p>
            </CardBody>
          </Card>
        )}
      </div>
    </>
  );
};

export default HotelDetailsPage;
