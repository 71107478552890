import ErrorAlert from "components/Alerts/error";
import SuccessAlert from "components/Alerts/succes";
import React, { useState } from "react";
import { ConfirmServiceOrder } from "services/orders.service";
import {
  ConfirmOrder,
  getOrdersCountsAndList,
} from "services/V2_APIs/orders.service";

const ServiceOrderConfirmation = ({
  activeOrder,
  getOrdersList,
  getOrderById,
  Status,
  toggleModell
}) => {
  let UserFirstname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "firstname"
  ];
  let UserLastname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "lastname"
  ];
  let Username = UserFirstname + " " + UserLastname;
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  let getPendingStatus = Status;
  const handleConfirmOrder = OrderId => {
    let DateandTime = new Date();
    const formattedDateTime = DateandTime.toISOString();
    let body = {
      orderConfirmedByStylist: true,
      orderConfirmedByStylistDateTime: formattedDateTime,
      admin:Username
    };
    ConfirmOrder(OrderId, body)
      .then(res => {
        if (res?.data) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
      })
      .catch(err => {
        seterror_msg(true);
      });
  };
  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    getOrdersList();
    getOrderById(activeOrder);
    // toggleModell();
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    getOrdersList();

    getOrderById(activeOrder);
    // toggleModell();
  };
  return (
    <>
      <div className="">
        <div className="bg-light p-1">
          <p className="mb-0">Order Status</p>
        </div>
        <button
          className={`btn btn-sm my-3 px-3 py-2 font-size-16  ${
            getPendingStatus === "Pending" ? "btn-primary-red" : "btn-success"
          }`}
          onClick={() => {
            if (getPendingStatus === "Pending") {
              handleConfirmOrder(activeOrder?._id);
            }
          }}
          disabled={getPendingStatus === "Pending" ? false : true}
        >
          {getPendingStatus === "Pending" ? "Confirm Order" : "Order Confirmed"}
        </button>
      </div>
      {success_msg ? (
        <SuccessAlert
          msg={`Order (#${activeOrder?.orderId}) is confirmed successfully`}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
      {error_msg ? (
        <ErrorAlert
          msg={"error"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
    </>
  );
};

export default ServiceOrderConfirmation;
