import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import "./OrderPageStyles.scss";
import Select from "react-select";
import moment from "moment";
import timesSlots from "utils/TimeSlots";
import { RescheduleOrder } from "services/V2_APIs/orders.service";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import Spinner from "components/spinner";
import { AssignStylistForHotelOrder } from "services/V2_APIs/hotel.service";
import SweetAlert from "react-bootstrap-sweetalert";

const AssignStylist = ({
  toggle,
  modal,
  AvailableProviders,
  providers,
  activeOrder,
  selecteditem,
  loading,
  getallOrders,
  getOrderById,
  toggleModel,
}) => {
  console.log(
    // AvailableProviders,
    "avail mm",
    // providers,
    // selecteditem,
    activeOrder
  );
  const [newProviderSelected, setNewProviderSelected] = useState({});
  const [setlectedWeek, setsetlectedWeek] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSelected, setTimeSelected] = useState();
  const [success_msg, setsuccess_msg] = useState(false);
  console.log(success_msg, "success msg");
  const [error_msg, seterror_msg] = useState(false);
  let UserFirstname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "firstname"
  ];
  let UserLastname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "lastname"
  ];
  let Username = UserFirstname + " " + UserLastname;

  const getSlotsArray = (selectedSlot, selecteditem) => {
    let slotsArray = [];
    // debugger;
    if (selecteditem?.slots?.length === 1) {
      slotsArray?.push(selectedSlot);
    } else {
      for (let i = 0; i < selecteditem?.slots?.length; i++) {
        slotsArray?.push(selectedSlot + i - 1);
      }
    }
    console.log(slotsArray, "slotsArrayslotsArrayslotsArray 1 item");
    return slotsArray;
  };
  const OnSubmit = () => {
    const date = moment(activeOrder?.appointmentDate, "DD-MMMM-YYYY");
    const updatedSelectedList = activeOrder?.selectedList.map(item => {
      if (item._id === selecteditem._id) {
        return {
          ...item,
          serviceid: item?.serviceid?._id,
          selectedOption: item?.selectedOption || [],
          selectedProduct: item?.selectedProduct || [],
          slots: getSlotsArray(item?.slots[0], item),
          amount: item?.amount,
          constantAmount: item?.amount,
          stylistName: newProviderSelected?.label,
          stylistExperience: newProviderSelected?.exp,
          quantity: item?.quantity,
          parentCategories: item?.serviceid?.parentCategory?.name,
          categories: item?.serviceid?.categories?.name,
          image: item?.serviceid?.images[0],
          slotDetails: activeOrder?.appointmentTime,
          dateDetails: {
            date: date.format("DD"),
            day: date.format("ddd"),
            dayFullName: date.format("dddd"),
            fullDate: date.format("DD-MM-YYYY"),
            fullYear: date.format("YYYY"),
            month: date.format("MMMM"),
          },
          stylistImage: newProviderSelected?.profileimage || "",
          userType: item?.serviceid?.type,
          ...(newProviderSelected?.companyId && {
            companyid: newProviderSelected?.companyId,
          }),
          ...(newProviderSelected?.value && {
            providerid: newProviderSelected?.value,
          }),
        };
      }
      return item;
    });

    // Construct the body with the updated list
    const body = {
      selectedList: updatedSelectedList,
      admin: Username,
    };

    console.log(body, "Payload mm");

    AssignStylistForHotelOrder(body, activeOrder?._id)
      .then(res => {
        console.log(res, "Assign Order response");
        if (res?.success === true && res?.data) {
          setsuccess_msg(true);
          toggle();
        } else {
          seterror_msg(true);
        }
      })
      .catch(e => {
        console.log(e, "Assign Order error");
        seterror_msg(true);
      });
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Assigned Stylist
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    getallOrders();
    getOrderById(activeOrder);
    // toggle();
    setTimeSelected({});
    setSelectedDate(null);
    setsetlectedWeek(null);
  };

  const closemodel = () => {
    toggle();
  };
  return (
    <>
      {loading ? (
        <Spinner loading={loading} />
      ) : (
        <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
          <ModalHeader toggle={toggle}>Update Stylist Availability</ModalHeader>
          <ModalBody className="bg-light">
            <Container fluid>
              <Row>
                <Col md="8">
                  <Card className="">
                    <CardBody className="p-2">
                      <h5>Selected Appointment Date and Time</h5>
                      <div>
                        <p className="mb-0">
                          {activeOrder?.appointmentDate} @{" "}
                          {activeOrder?.appointmentTime}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="d-flex align-items-center Available_provider_container flex-wrap">
                <h6 className="me-3">
                  Total Available Providers : {AvailableProviders?.length}
                </h6>
                {AvailableProviders?.map((ind, indx) => {
                  return (
                    <div key={indx} className="profile_images mb-2">
                      <img src={ind?.profileimage} />
                    </div>
                  );
                })}
              </div>
              <div>
                <Row>
                  <Col md={6}>
                    {AvailableProviders && AvailableProviders?.length ? (
                      <div>
                        <Select
                          name="parentCat"
                          id="parentCat"
                          // value={newProviderSelected}
                          placeholder="Select Provider..."
                          onChange={event => {
                            setNewProviderSelected(event);
                          }}
                          options={AvailableProviders}
                        />
                      </div>
                    ) : (
                      <Card>
                        <CardBody>No Providers are available</CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="d-flex justify-content-end mt-3">
                <Button
                  color="light"
                  className="w-md btn-sm me-2"
                  onClick={closemodel}
                >
                  cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  className="w-md btn-sm float-end"
                  onClick={OnSubmit}
                >
                  Save
                </Button>
              </div>
            </Container>
          </ModalBody>
        </Modal>
      )}

      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </>
  );
};

export default AssignStylist;
