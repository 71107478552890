import React, { useState, useEffect } from "react";

const AnimatedViewsCounter = ({ endValue }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // if (endValue === undefined || endValue <= 0) return;

    const duration = 2000;
    const stepTime = Math.abs(Math.floor(duration / endValue));

    let currentCount = 0;

    const timer = setInterval(() => {
      currentCount += 1;
      setCount(prev => (currentCount <= endValue ? currentCount : endValue));

      if (currentCount >= endValue) {
        clearInterval(timer);
      }
    }, stepTime);

    return () => clearInterval(timer);
  }, [endValue]);

  return (
    <div className="d-flex align-items-center  justify-content-center mt-3">
      <div className="me-2 ">
        <i className="fa fa-eye text-info font-size-20"></i>
      </div>
      <div className="font-size-18 fw-bold">{count}</div>
    </div>
  );
};

export default AnimatedViewsCounter;
