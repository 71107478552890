import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import csc from "countries-states-cities";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import ProfileImage from "../../components/profileImage";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Spinner from "components/spinner";
import { createImageUrl } from "services/imageUrl.service";
import {
  createHotel,
  UpdateHotelDetails,
} from "services/V2_APIs/hotel.service";
import { getSkuCode } from "services/ecommerce/product.service";
const defaultStateList = [
  {
    label: "please Select Country",
    value: "please Select Country",
    isDisabled: true,
  },
];
const defaultCityList = [
  {
    label: "please Select State",
    value: "please Select State",
    isDisabled: true,
  },
];

const defaultValues = {
  hotelId: "",
  name: "",
  description: "",
  address: {
    location: {
      locationType: "Point",
      latitude: 0,
      longitude: 0,
    },
    address1: "",
    address2: "",
    area: "",
    landmark: "",
    city: "",
    state: "",
    country: "India",
    zipcode: "",
    displayAddress: "",
  },
  images: {
    backgroundImage: "",
    logo: "",
    secondaryLogo: "",
  },
  styles: {
    theme1: "",
    theme2: "",
  },
  prices: {
    markupPercentage: 0,
  },
  contactDetails: {
    contacts: [
      { name: "", position: "", number: "", notes: "", isPrimary: false },
    ],
  },
  type: "hotel",
};

const CreateHotel = ({
  toggle,
  modal,
  reload,
  ActiveData,
  handleActiveOrder,
}) => {
  console.log(ActiveData, "aciviveSattttta");
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [name, setName] = useState("");
  console.log(name, "nameee");
  const [hotelId, setHotelId] = useState();
  const [loading, setLoading] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState(defaultStateList);
  const [citiesList, setCitiesList] = useState(defaultCityList);
  const [selectedCountry, setSelectedCountry] = useState({
    value: "IN",
    id: 101,
    label: "India",
  });
  const [selecteddState, setselecteddState] = useState({});
  const [seletedCity, setseletedCity] = useState({});
  const initialValues = ActiveData
    ? { ...defaultValues, ...ActiveData }
    : defaultValues;

  const validationSchema = Yup.object().shape({
    // image: Yup.string().required("Please Upload Image"),
  });

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  useEffect(() => {
    if (!ActiveData?._id) {
      setHotelId("");
      setselecteddState({});
      setseletedCity({});
    }
  }, [ActiveData]);
  useEffect(() => {
    //Get All Countries List
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
    setSelectedCountry({
      value: "IN",
      id: 101,
      label: "India",
    });
    let stateArray = [];
    csc.getStatesOfCountry(101).forEach(ele => {
      let stateObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      stateArray.push(stateObj);
    });
    setStatesList(stateArray);
  }, []);
  function handleSelectCountry(event) {
    let stateArray = [];
    csc.getStatesOfCountry(event.id).forEach(ele => {
      let stateObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      stateArray.push(stateObj);
    });
    setStatesList(stateArray);
  }

  function handleSelectState(event) {
    let citiesArr = [];
    csc.getCitiesOfState(event.id).forEach(ele => {
      let cityObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      citiesArr.push(cityObj);
    });
    setCitiesList(citiesArr);
  }
  const handleImageChange = async image => {
    try {
      let imageFormData = new FormData();
      imageFormData.set("image", image);

      const data = await createImageUrl(imageFormData);

      if (data.success) {
        return data?.image;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const saveChanges = values => {
    console.log(values, "values ");
    setLoading(true);
    let payload = {
      hotelId: hotelId,
      name: values.name,
      description: values.description,
      address: {
        location: {
          locationType: values?.address?.location?.locationType,
          latitude: parseFloat(values?.address?.location?.latitude),
          longitude: parseFloat(values?.address?.location?.longitude),
        },
        address1: values?.address?.address1,
        address2: values?.address?.address2,
        area: values?.address?.area,
        landmark: values?.address?.landmark,
        country: values?.address?.country,
        state: values?.address?.state,
        city: values?.address?.city,
        zipcode: values?.address?.zipcode,
        displayAddress: `${values?.address?.address1},${values?.address?.address2},${values?.address?.area},${values?.address?.state},${values?.address?.city},${values?.address?.zipcode}`,
      },
      images: {
        backgroundImage: values?.images?.backgroundImage,
        logo: values?.images?.logo,
        secondaryLogo: values?.images?.secondaryLogo,
      },
      styles: {
        theme1: values?.styles?.theme1,
        theme2: values?.styles?.theme2,
      },
      prices: {
        markupPercentage: values?.prices?.markupPercentage,
      },
      contactDetails: { contacts: values?.contactDetails?.contacts },
      type: "hotel",
    };
    console.log(payload, "payloaddddd");
    createHotel(payload)
      .then(res => {
        if (res?.success === true) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };
  const generateRandomCode = () => {
    getSkuCode()
      .then(res => {
        setHotelId(res?.data);
        setValues({ ...values, ["hotelId"]: hotelId });
      })
      .catch(err => console.log(err));
  };
  const editChanges = values => {
    console.log(values, "values ");
    setLoading(true);
    let payload = {
      hotelId: hotelId,
      name: values.name,
      description: values.description,
      address: {
        location: {
          locationType: values?.address?.location?.locationType,
          latitude: parseFloat(values?.address?.location?.latitude),
          longitude: parseFloat(values?.address?.location?.longitude),
        },
        address1: values?.address?.address1,
        address2: values?.address?.address2,
        area: values?.address?.area,
        landmark: values?.address?.landmark,
        city: values?.address?.city,
        state: values?.address?.state,
        country: values?.address?.country,
        zipcode: values?.address?.zipcode,
        displayAddress: `${values?.address?.address1},${values?.address?.address2},${values?.address?.area},${values?.address?.state},${values?.address?.city},${values?.address?.zipcode}`,
      },
      images: {
        backgroundImage: values?.images?.backgroundImage,
        logo: values?.images?.logo,
        secondaryLogo: values?.images?.secondaryLogo,
      },
      styles: {
        theme1: values?.styles?.theme1,
        theme2: values?.styles?.theme2,
      },
      prices: {
        markupPercentage: values?.prices?.markupPercentage,
      },
      contactDetails: { contacts: values?.contactDetails?.contacts },
      type: "hotel",
    };

    console.log(payload, "payload edit");

    UpdateHotelDetails(ActiveData?._id, payload)
      .then(res => {
        if (res?.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
        handleActiveOrder(ActiveData);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };
  function getcountryname(code) {
    const fullcountryname = countriesList?.filter(ele =>
      ele?.value?.includes(code)
    );
    return fullcountryname;
  }

  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
    setValues("");
    setHotelId("");
    setselecteddState({});
    setseletedCity({});
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    setValues("");
    setselecteddState({});
    setseletedCity({});
    setHotelId("");
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        {ActiveData?._id
          ? "Successfully Hotel Edited"
          : "Successfully Hotel created"}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Spinner loading={loading} />
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
          {ActiveData?._id ? "Edit Hotel Details" : "Create Hotel"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid={false}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              // onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleChange,
                setFieldTouched,
                values,
              }) => {
                useEffect(() => {
                  if (ActiveData?._id) {
                    const getfullcountryname = getcountryname(
                      ActiveData?.address?.country
                    );
                    setHotelId(ActiveData?.hotelId);
                    setSelectedCountry({
                      label: ActiveData?.address?.country,
                      value: getfullcountryname[0]?.label,
                    });
                    setselecteddState({
                      label: ActiveData?.address?.state,
                      value: ActiveData?.address?.state,
                    });
                    setseletedCity({
                      label: ActiveData?.address?.city,
                      value: ActiveData?.address?.city,
                    });
                  }
                }, [ActiveData]);

                return (
                  <Form>
                    <Card className="mb-3">
                      <CardBody className="">
                        <Row className="">
                          <Col md={5} className="">
                            <FormGroup className="">
                              <Label
                                htmlFor="validationCustom01"
                                className="d-flex align-items-center justify-content-between"
                              >
                                <div>
                                  <div>
                                    Hotel Id{" "}
                                    <span className="text-danger">*</span>
                                  </div>
                                </div>
                                <Button
                                  className=" border border-0 p-0 px-2 text-info "
                                  color="light"
                                  role="button"
                                  onClick={() => generateRandomCode()}
                                >
                                  Generate code
                                </Button>
                              </Label>
                              <Input
                                name="hotelId"
                                placeholder="Generate code"
                                type="text"
                                classtitle="form-control"
                                id="hotelId"
                                // onChange={handleChange}
                                disabled
                                value={
                                  hotelId
                                    ? hotelId?.toUpperCase()
                                    : values?.hotelId?.toUpperCase()
                                }
                                invalid={
                                  touched.hotelId && errors.hotelId
                                    ? true
                                    : false
                                }
                              />

                              {touched.hotelId && errors.hotelId ? (
                                <FormFeedback type="invalid">
                                  {errors.hotelId}
                                  {touched.hotelId}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={6} className="">
                            <FormGroup className="">
                              <Label htmlFor="validationCustom01">
                                Hotel Name
                              </Label>
                              <Input
                                name="name"
                                placeholder="name"
                                type="text"
                                classtitle="form-control"
                                id="name"
                                onChange={handleChange}
                                value={values.name}
                                invalid={
                                  touched.name && errors.name ? true : false
                                }
                              />

                              {touched.name && errors.name ? (
                                <FormFeedback type="invalid">
                                  {errors.name}
                                  {touched.name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={6} className="">
                            <FormGroup className="">
                              <Label htmlFor="markupPercentage">
                                Markup Percentage
                              </Label>
                              <Input
                                name="prices.markupPercentage" // Correct nested name
                                type="number"
                                className="form-control"
                                id="markupPercentage"
                                onChange={e => {
                                  // Update the Formik state
                                  const markupPercentage = parseFloat(
                                    e.target.value
                                  );
                                  setFieldValue(
                                    "prices.markupPercentage",
                                    markupPercentage
                                  );
                                }}
                                value={values.prices.markupPercentage}
                                invalid={
                                  touched.prices?.markupPercentage &&
                                  errors.prices?.markupPercentage
                                    ? true
                                    : false
                                }
                              />
                              {touched.prices?.markupPercentage &&
                              errors.prices?.markupPercentage ? (
                                <FormFeedback type="invalid">
                                  {errors.prices.markupPercentage}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col className="" sm={6}>
                            <Label htmlFor="description">Description</Label>

                            <textarea
                              name="description"
                              className="form-control"
                              id="description"
                              rows={3}
                              onChange={handleChange}
                              value={values.description}
                            ></textarea>
                            {touched.description && errors.description ? (
                              <FormFeedback type="invalid">
                                {errors.description}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md={6} className="mt-2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">
                                Main Logo
                                <span className="text-danger">*</span>
                              </Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={async acceptedFiles => {
                                  const formattedFiles = acceptedFiles.map(
                                    file =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        formattedSize: formatBytes(file.size),
                                      })
                                  );

                                  if (formattedFiles.length > 0) {
                                    const file = acceptedFiles[0];
                                    try {
                                      const imageUrl = await handleImageChange(
                                        file
                                      );
                                      console.log(
                                        imageUrl,
                                        "Generated Image URL"
                                      );
                                      setFieldValue("images.logo", imageUrl);
                                    } catch (error) {
                                      console.error(
                                        "Error creating image URL:",
                                        error
                                      );
                                    }
                                  }
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.images.logo && (
                                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <ProfileImage
                                            image={values?.images?.logo}
                                            height="80"
                                            classNames="avatar-sm rounded bg-light"
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={6} className="mt-2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">
                                Secondary Logo
                              </Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={async acceptedFiles => {
                                  const formattedFiles = acceptedFiles.map(
                                    file =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        formattedSize: formatBytes(file.size),
                                      })
                                  );

                                  if (formattedFiles.length > 0) {
                                    const file = acceptedFiles[0];
                                    try {
                                      const imageUrl = await handleImageChange(
                                        file
                                      );
                                      console.log(
                                        imageUrl,
                                        "Generated Image URL"
                                      );
                                      setFieldValue(
                                        "images.secondaryLogo",
                                        imageUrl
                                      );
                                    } catch (error) {
                                      console.error(
                                        "Error creating image URL:",
                                        error
                                      );
                                    }
                                  }
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.images?.secondaryLogo && (
                                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <ProfileImage
                                            image={
                                              values?.images?.secondaryLogo
                                            }
                                            height="80"
                                            classNames="avatar-sm rounded bg-light"
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <h5 className="fw-bold ms-2 mb-3">Address Details</h5>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col sm={4} className="">
                            <Label htmlFor="country" className="col-form-label">
                              Country
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="address.country"
                              id="country"
                              onChange={event => {
                                setFieldValue("address.country", event?.label);

                                handleSelectCountry(event);
                              }}
                              // isDisabled
                              options={countriesList}
                              value={selectedCountry}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                          <Col className="" sm={4}>
                            <Label htmlFor="state" className="col-form-label">
                              State
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="address.state"
                              id="state"
                              onChange={event => {
                                if (event) {
                                  handleSelectState(event);
                                  setFieldValue("address.state", event.label);
                                  // setFieldValue("city", null);
                                  setselecteddState(event);
                                }
                              }}
                              options={statesList}
                              value={
                                selecteddState
                                  ? selecteddState
                                  : values?.address?.state
                              }
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                          <Col className="" sm={4}>
                            <Label htmlFor="city" className="col-form-label">
                              City
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="address.city"
                              id="city"
                              onChange={event => {
                                // console.log(event);
                                setFieldValue("address.city", event.label);
                                setseletedCity(event);
                              }}
                              options={citiesList}
                              value={
                                seletedCity
                                  ? seletedCity
                                  : values?.address?.city
                              }
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Address 1<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="address.address1"
                                placeholder="Address 1"
                                type="text"
                                classtitle="form-control"
                                id="address1"
                                onChange={e => {
                                  // Update the Formik state
                                  setFieldValue(
                                    "address.address1",
                                    e.target.value
                                  );
                                }}
                                value={values?.address?.address1}
                                invalid={
                                  touched.address1 && errors.address1
                                    ? true
                                    : false
                                }
                              />

                              {touched.address1 && errors.address1 ? (
                                <FormFeedback type="invalid">
                                  {errors.address1}
                                  {touched.address1}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Address 2<span className="text-danger">*</span>
                                (Hotel Name)
                              </Label>
                              <Input
                                name="address.address2"
                                placeholder="Address 2"
                                type="text"
                                classtitle="form-control"
                                id="address2"
                                onChange={e => {
                                  // Update the Formik state
                                  setFieldValue(
                                    "address.address2",
                                    e.target.value
                                  );
                                }}
                                value={values?.address?.address2}
                                invalid={
                                  touched.address2 && errors.address2
                                    ? true
                                    : false
                                }
                              />

                              {touched.address2 && errors.address2 ? (
                                <FormFeedback type="invalid">
                                  {errors.address2}
                                  {touched.address2}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="">
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Area <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="address.area"
                                placeholder="area"
                                type="text"
                                classtitle="form-control"
                                id="area"
                                onChange={e => {
                                  // Update the Formik state
                                  setFieldValue("address.area", e.target.value);
                                }}
                                value={values?.address?.area}
                                invalid={
                                  touched.area && errors.area ? true : false
                                }
                              />

                              {touched.area && errors.area ? (
                                <FormFeedback type="invalid">
                                  {errors.area}
                                  {touched.area}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                LandMark
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="address.landmark"
                                placeholder="landmark"
                                type="text"
                                classtitle="form-control"
                                id="landmark"
                                onChange={e => {
                                  // Update the Formik state
                                  setFieldValue(
                                    "address.landmark",
                                    e.target.value
                                  );
                                }}
                                value={values?.address?.landmark}
                                invalid={
                                  touched.landmark && errors.landmark
                                    ? true
                                    : false
                                }
                              />

                              {touched.landmark && errors.landmark ? (
                                <FormFeedback type="invalid">
                                  {errors.landmark}
                                  {touched.landmark}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col className="" sm={4}>
                            <Label htmlFor="zipcode" className="col-form-label">
                              Zipcode
                              <span className="text-danger">*</span>
                            </Label>

                            <Input
                              type="text"
                              className="form-control"
                              id="zipcode"
                              placeholder="Enter Your Zipcode"
                              name="address.zipcode"
                              onChange={e => {
                                // Update the Formik state
                                setFieldValue(
                                  "address.zipcode",
                                  e.target.value
                                );
                              }}
                              value={values?.address?.zipcode}
                            />
                          </Col>
                          <Col className="" sm={4}>
                            <Label
                              htmlFor="locationtype"
                              className="col-form-label"
                            >
                              Location Type
                            </Label>

                            <Input
                              type="text"
                              className="form-control"
                              id="locationtype"
                              placeholder="location type"
                              name="address.location.locationType"
                              onChange={e => {
                                // Update the Formik state
                                setFieldValue(
                                  "address.location.locationType",
                                  e.target.value
                                );
                              }}
                              value={values?.address?.location?.locationType}
                            />
                          </Col>
                          <Col className="" sm={4}>
                            <Label
                              htmlFor="latitude"
                              className="col-form-label"
                            >
                              Latitude
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="latitude"
                              placeholder="Latitude"
                              name="address.location.latitude"
                              onChange={e => {
                                // Update the Formik state
                                setFieldValue(
                                  "address.location.latitude",
                                  e.target.value
                                );
                              }}
                              value={values?.address?.location?.latitude}
                            />
                          </Col>

                          <Col className="mt-2" sm={4}>
                            <Label
                              htmlFor="longitude"
                              className="col-form-label"
                            >
                              Longitude
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              id="longitude"
                              placeholder="Longitude"
                              name="address.location.longitude"
                              onChange={e => {
                                // Update the Formik state
                                setFieldValue(
                                  "address.location.longitude",
                                  e.target.value
                                );
                              }}
                              value={values?.address?.location?.longitude}
                            />
                          </Col>

                          <Col className="mt-2" sm={12}>
                            <Label htmlFor="" className="col-form-label">
                              Full Address
                            </Label>
                            <textarea
                              type="number"
                              className="form-control"
                              id=""
                              placeholder=""
                              name=""
                              disabled
                              value={[
                                values?.address?.address1,
                                values?.address?.address2,
                                values?.address?.area,
                                values?.address?.state,
                                values?.address?.city,
                                values?.address?.zipcode,
                              ]
                                .filter(Boolean)
                                .join(",")}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <h5 className="fw-bold ms-2 mb-3"> Contact Information</h5>
                    <Card>
                      <CardBody>
                        <FieldArray
                          name="contactDetails.contacts"
                          render={({ push, remove }) => (
                            <>
                              {values?.contactDetails?.contacts?.map(
                                (contact, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="border p-2 m-1 rounded"
                                    >
                                      <Row key={index} className="">
                                        <Col md={12}>
                                          <Row>
                                            <Col md={11}>
                                              <Row>
                                                <Col sm={4}>
                                                  <Label
                                                    htmlFor={`contacts.${index}.name`}
                                                    className="col-form-label"
                                                  >
                                                    Name
                                                  </Label>
                                                  <Input
                                                    type="text"
                                                    className="form-control"
                                                    id={`contacts.${index}.name`}
                                                    placeholder="Name"
                                                    name={`contactDetails.contacts[${index}].name`}
                                                    onChange={handleChange}
                                                    value={contact.name}
                                                  />
                                                </Col>
                                                <Col sm={4}>
                                                  <Label
                                                    htmlFor={`contacts.${index}.position`}
                                                    className="col-form-label"
                                                  >
                                                    Position
                                                  </Label>
                                                  <Input
                                                    type="text"
                                                    className="form-control"
                                                    id={`contacts.${index}.position`}
                                                    placeholder="Position"
                                                    name={`contactDetails.contacts[${index}].position`}
                                                    onChange={handleChange}
                                                    value={contact.position}
                                                  />
                                                </Col>
                                                <Col sm={4}>
                                                  <Label
                                                    htmlFor={`contacts.${index}.number`}
                                                    className="col-form-label"
                                                  >
                                                    Number
                                                  </Label>

                                                  <PhoneInput
                                                    className="custom_country_phoneNumber"
                                                    id={`contacts.${index}.number`}
                                                    placeholder="mobile number"
                                                    name={`contactDetails.contacts[${index}].number`}
                                                    international={false}
                                                    defaultCountry={
                                                      selectedCountry?.value ||
                                                      "IN"
                                                    }
                                                    value={contact.number}
                                                    onChange={value => {
                                                      console.log(
                                                        value,
                                                        "vallllll"
                                                      );
                                                      setFieldValue(
                                                        `contactDetails.contacts[${index}].number`,
                                                        value
                                                      );
                                                    }}
                                                  />
                                                </Col>
                                                <Col sm={6}>
                                                  <Label className="col-form-label">
                                                    notes
                                                  </Label>
                                                  <textarea
                                                    name={`contactDetails.contacts[${index}].notes`}
                                                    className="form-control"
                                                    rows={3}
                                                    placeholder="Notes"
                                                    onChange={handleChange}
                                                    value={contact.notes}
                                                  ></textarea>
                                                </Col>
                                              </Row>
                                            </Col>
                                            <Col md={1}>
                                              <div className="ser_row_action">
                                                {values?.contactDetails
                                                  ?.contacts?.length === 1 ? (
                                                  ""
                                                ) : (
                                                  <i
                                                    className="mdi mdi-delete font-size-18 text-danger"
                                                    onClick={() => {
                                                      remove(index);
                                                    }}
                                                  ></i>
                                                )}
                                                {values?.contactDetails
                                                  ?.contacts.length ===
                                                  index + 1 && (
                                                  <i
                                                    className="mdi mdi-plus-thick font-size-18 text-success"
                                                    onClick={() =>
                                                      push({
                                                        name: "",
                                                        position: "",
                                                        number: "",
                                                        notes: "",
                                                        isPrimary: false,
                                                      })
                                                    }
                                                  ></i>
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                        />
                      </CardBody>
                    </Card>
                    <h5 className="fw-bold ms-2 mb-3">Theme Details</h5>
                    <Card>
                      <CardBody>
                        <Row className="mt-3">
                          <Col md={4}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Theme color code 1
                              </Label>
                              <Input
                                name="styles.theme1"
                                placeholder="Hexcode 1"
                                type="text"
                                classtitle="form-control"
                                id="theme1"
                                onChange={e => {
                                  // Update the Formik state
                                  setFieldValue(
                                    "styles.theme1",
                                    e.target.value
                                  );
                                }}
                                value={values?.styles?.theme1}
                                invalid={
                                  touched.theme1 && errors.theme1 ? true : false
                                }
                              />

                              {touched.theme1 && errors.theme1 ? (
                                <FormFeedback type="invalid">
                                  {errors.theme1}
                                  {touched.theme1}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Theme color code 1
                              </Label>
                              <Input
                                name="styles.theme2"
                                placeholder="Hexcode 2"
                                type="text"
                                classtitle="form-control"
                                id="theme2"
                                onChange={e => {
                                  // Update the Formik state
                                  setFieldValue(
                                    "styles.theme2",
                                    e.target.value
                                  );
                                }}
                                value={values?.styles?.theme2}
                                invalid={
                                  touched.theme2 && errors.theme2 ? true : false
                                }
                              />

                              {touched.theme2 && errors.theme2 ? (
                                <FormFeedback type="invalid">
                                  {errors.theme2}
                                  {touched.theme2}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <hr></hr>
                    <div className="d-flex justify-content-end">
                      <Button
                        color="light"
                        className="w-md btn-sm me-3"
                        onClick={leaveToList}
                      >
                        cancel
                      </Button>
                      {!ActiveData?._id ? (
                        <Button
                          color="primary"
                          type="submit"
                          className="w-md btn-sm"
                          onClick={() => saveChanges(values)}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          className="w-md btn-sm"
                          type="submit"
                          onClick={() => editChanges(values)}
                        >
                          Save Changes
                        </Button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>

            {success_msg ? successPopup() : null}
            {error_msg ? errorPopup() : null}
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateHotel;
