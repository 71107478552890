import Breadcrumb from "components/Common/Breadcrumb";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { getAllHotelLogs } from "services/V2_APIs/hotel.service";
import _ from "lodash";
import noImage from "assets/images/PicUpload.jpg";
import { Link } from "react-router-dom";
import Spinner from "components/spinner";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import ProfileImage from "components/profileImage";

const HotelLogs = () => {
  const [HotelLogsData, setHotelLogsData] = useState([]);
  const [Hotels, setHotels] = useState([]);
  const [HotelsLogsList, setHotelsLogsList] = useState([]);
  const [hotelNames, setHotelNames] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllHotelLogsList();
  }, [selectedHotel]);
  const getUniqueHotels = data => {
    const uniqueHotels = _.uniqBy(data, "hotelId._id");
    setHotelLogsData(uniqueHotels);
    setHotels(uniqueHotels);
    setHotelNames(buildOptions(uniqueHotels));
  };
  const buildOptions = list => {
    const result = list?.map(ele => {
      return {
        label: ele.hotelId?.name,
        value: ele.hotelId?.name,
        id: ele.hotelId?._id,
      };
    });
    const viewAllOption = {
      label: "View All",
      value: "view All",
    };

    return [...result, viewAllOption];
  };
  const getAllHotelLogsList = () => {
    setLoading(true);
    getAllHotelLogs()
      .then(res => {
        if (res?.success === true) {
          const first50Hotels = res?.data?.slice(0, 50);
          // debugger
          getUniqueHotels(res?.data);
          if (!selectedHotel?.value || selectedHotel?.value === "view All") {
            setHotelsLogsList(first50Hotels);
          } else {
            let filteredHotels = first50Hotels?.filter(
              ele => ele?.hotelId?._id === selectedHotel?.id
            );
            setHotelsLogsList(filteredHotels);
          }

          setLoading(false);
        } else {
          setHotelLogsData([]);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleSearch = search => {
    if (search) {
      let tempList = _.cloneDeep(HotelLogsData);
      let filterList = tempList.filter(ele =>
        ele?.hotelId?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setHotelLogsData(filterList);
    } else {
      setHotelLogsData(Hotels);
    }
  };
  const pageOptions = {
    sizePerPage: 50,
    totalSize: HotelsLogsList?.length,
    custom: true,
  };
  const logsList = () => [
    {
      dataField: "hotelId.hotelId?.images.logo",
      text: "Sl. No",
      formatter: (cellContent, row, rowIndex) => <div>{rowIndex + 1}</div>,
    },
    {
      dataField: "hotelId.hotelId?.images.logo",
      text: "Logo",
      formatter: (cellContent, row) => (
        <ProfileImage
          image={row?.hotelId?.images?.logo}
          classNames="avatar-sm"
        />
      ),
    },
    {
      dataField: "hotelId.hotelId",
      text: "Hotel Id",
      formatter: (cellContent, row) => {
        return (
          <>
            <div>#{row?.hotelId?.hotelId}</div>
          </>
        );
      },
    },
    {
      dataField: "hotelId.name",
      text: "Name",
      formatter: (cellContent, row) => {
        return (
          <>
            <div>{row?.hotelId?.name}</div>
          </>
        );
      },
    },

    {
      dataField: "hotelId.address.address1",
      text: "Address",
      formatter: (cellContent, row) => {
        return (
          <>
            <div>{row?.hotelId?.address?.displayAddress}</div>
            <span>LandMark : {row?.hotelId?.address?.landmark}</span>
          </>
        );
      },
    },
    {
      dataField: "hotelId.contactDetails.primaryContact",
      text: "Contacts",
      formatter: (cellContent, row) => {
        return (
          <>
            <p className="mb-1">
              {row?.hotelId?.contactDetails?.primaryContact
                ? row?.hotelId?.contactDetails?.primaryContact
                : "-"}
            </p>
            <p>
              {row?.hotelId?.contactDetails?.secondaryContact
                ? row?.hotelId?.contactDetails?.secondaryContact
                : ""}
            </p>
          </>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <div>
            <Breadcrumb title="Hotels" breadcrumbItem="Hotel Logs" />
            <BreadcrumbSub subtile="Hotel Logs" SubBreadcrumbItem="Hotels" />
            <div>
              <div className="search-box ms-4 individual_search">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <input
                    className="form-control py-2 bg-white"
                    type="search"
                    id="example-search-input"
                    placeholder="Search..."
                    onChange={event => {
                      handleSearch(event.target.value);
                    }}
                  />
                </div>
              </div>
              {HotelLogsData?.length === 0 ? (
                <Card className="mt-3">
                  <CardBody>
                    <div className="p-5 text-center">
                      <h5>No results found</h5>
                    </div>
                  </CardBody>
                </Card>
              ) : (
                <Card className="bg-light my-3">
                  <CardBody>
                    {/* Horizontal Scroll Wrapper */}
                    <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                      <Row
                        className="services-card flex-nowrap"
                        style={{ display: "inline-flex" }}
                      >
                        {HotelLogsData?.map((ele, index) => {
                          let editUrl = `/Hotel-Logs-List/${ele?.hotelId?._id}?hotelId=${ele?.hotelId?.hotelId}`;
                          return (
                            <Col
                              key={index}
                              className=""
                              sm={6}
                              md={6}
                              xl={4}
                              xxl={4}
                              // style={{
                              //   flex: "0 0 auto",
                              //   width: "400px",
                              //   marginRight: "15px",
                              // }}
                            >
                              <Link to={editUrl}>
                                <Card className="Hotel_cards">
                                  <CardBody>
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <div className="text-center">
                                          <img
                                            className="border p-1 rounded"
                                            src={
                                              !ele?.hotelId?.images?.logo ||
                                              ele?.hotelId?.images?.logo ===
                                                "undefined"
                                                ? noImage
                                                : ele?.hotelId?.images?.logo
                                            }
                                            alt=""
                                            width={100}
                                            height={100}
                                            role="button"
                                          />
                                        </div>
                                      </div>
                                      <div className="ms-3">
                                        <h6 className="fw-bold font-size-16 individual_name">
                                          #{" "}
                                          {ele?.hotelId?.hotelId?.toUpperCase()}
                                        </h6>
                                        <p className="fw-bold mb-1">
                                          {ele?.hotelId?.name}
                                        </p>
                                        <p className="mb-1 text-wrap">
                                          {
                                            ele?.hotelId?.address
                                              ?.displayAddress
                                          }
                                        </p>
                                        {ele?.hotelId?.contactDetails
                                          ?.primaryContact && (
                                          <div>
                                            <span className="fw-bold">
                                              Contacts:
                                            </span>
                                            <span className="ms-1">
                                              {
                                                ele?.hotelId?.contactDetails
                                                  ?.primaryContact
                                              }
                                              {ele?.hotelId?.contactDetails
                                                ?.secondaryContact
                                                ? `, ${ele?.hotelId?.contactDetails?.secondaryContact}`
                                                : ""}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
          <div className="">
            <div>
              <Row>
                <Col md={12}>
                  <FormGroup className="form_group" style={{ width: "200px" }}>
                    <Label htmlFor="hotel" className="fw-bold">
                      Select hotel
                    </Label>
                    <Select
                      id="hotel"
                      options={hotelNames}
                      placeholder="Select.."
                      classNamePrefix="select2-selection"
                      onChange={event => {
                        setSelectedHotel(event);
                      }}
                      value={selectedHotel}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="HotelsLogsList"
                      columns={logsList()}
                      data={HotelsLogsList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="HotelsLogsList"
                          data={HotelsLogsList}
                          columns={logsList()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="HotelsLogsList"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row> */}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    {HotelsLogsList?.length == 0 && (
                      <p className="text-center"> No Data Found</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HotelLogs;
